import 'bootstrap'
import '../shared/event_packages_filter'
//import '../../shared/nav/collapsing_nav'
import '../shared/phone'
import '../shared/buyers_page_personalizer'
// import './show_hide_content'
// import './banner-scroll-effect'
// import './gallery-slider'
import '../default/carousel_bs5'
import '../default/confirmation'
// import './hp-carousel'
import '../shared/bs5/nav/centered_logo_nav'
import './moves-previous-next-package'
import '../full-gpe/trustbox'

import AddToCartForms from '../shared/add_to_cart_forms'
import HotelIndex from '../shared/add_to_cart_forms/h4h_hotels/hotel_index'
import { store } from'../default/store'
import VueLoader from '../default/vue-loader'
import CartNav from '../shared/nav/nav_cart'
import Checkout from '../shared/checkout'
import DepositRedemptions from '../shared/deposit_redemptions'
import PriceFilter from '../shared/price_filter'
import CurrencyDropdown from '../shared/currency_dropdowns'

document.addEventListener('turbo:load', () => {
  AddToCartForms.loadBottomForm()
  CartNav.addCartToNavbar()
  DepositRedemptions.findCustomerDeposits()
  Checkout.loadWithAddOn()
  VueLoader.loadVueComponent(HotelIndex, document.getElementById('hotelIndex'), store)
  CurrencyDropdown.loadCurrencyDropdown()
  if(document.getElementById('seating_chart_price')) {
    VueLoader.loadVueComponent(PriceFilter, document.getElementById('seating_chart_price'), store)
  }
})
